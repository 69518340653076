<template>
    <BaseDialog>
        <div v-if="!isLoading && order.id && refund.order_key">
            <HeaderOrder :order="order" :backTo="'/order/' + order.order_key + '/faq'" />

            <InfoOrder
                :info="order.info"
                :seller="order.seller"
            ></InfoOrder>

            <TableOrder
                v-if="order.products"
                :products="order.products" 
                :amount="order.amount" 
                :shipping="order.shipping"
                :discount="order.discount"
            ></TableOrder>

            <form class="box p-3" @submit.prevent="refundSumbit">

                <h6>Solicitação de reembolso:</h6>

                <div class="row">
                    <div class="col-12">
                        <BaseSelect 
                            label="Motivo da solicitação:"
                            :items="refund.reasons_list"
                            id="refund_reason"
                            v-model="inputs.refund_reason"
                            :page="page"
                        ></BaseSelect>
                    </div>

                    <div class="col-12">
                        <BaseInput
                            label="Escreva os detalhes da solicitação:"
                            type="text"
                            id="refund_text"
                            textarea
                            v-model="inputs.refund_text"
                            :page="page"
                        />
                    </div>

                    <div class="col-12">
                        <BaseFileUpload
                            label="Imagens"
                            id="refund_images"
                            v-model="inputs.refund_images"
                            :page="page"
                            multiple
                            accept="image/png, image/gif, image/jpeg, image/jpg"
                            info="Imagens de prova ou contestação, no máximo 3 arquivos."
                        ></BaseFileUpload>
                    </div>

                </div>

                <div v-if="order.payment_info.type != 'credit-card'" class="row">

                    <div class="col-md-6">
                        <BaseInput
                            label="Nome completo"
                            type="text"
                            id="name"
                            v-model="inputs.name"
                            :page="page"
                        />
                    </div>

                    <div class="col-md-6">
                        <BaseInput
                            label="CPF"
                            type="text"
                            id="cpf"
                            v-model="inputs.cpf"
                            maxlength="14"
                            :page="page"
                            mask="###.###.###-##"
                        />
                    </div>

                    <div class="col-12">
                        <BaseSelect 
                            label="Receber reembolso por meio de:"
                            :items="refund_methods"
                            v-model="inputs.refund_method"
                            id="refund_method"
                            :page="page"
                        ></BaseSelect>
                    </div>

                    <div v-if="inputs.refund_method == 'pix'" class="col-12">
                        <div class="row">
                            <div class="col-md-6">
                                <BaseSelect 
                                    label="Tipo de Chave Pix:"
                                    :items="refund.pix_type"
                                    v-model="inputs.pix_type"
                                    id="pix_type"
                                    :page="page"
                                    @change="changePixType"
                                ></BaseSelect>
                            </div>

                            <div class="col-md-6">
                                <BaseInput
                                    label="Chave Pix"
                                    :type="pix_key_field"
                                    id="pix_key"
                                    v-model="inputs.pix_key"
                                    :page="page"
                                    :mask="pix_key_type"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="inputs.refund_method == 'ted'" class="col-12">
                        <div class="row">

                            <div class="col-md-6">
                                <BaseSelect 
                                    label="Banco"
                                    :items="refund.banks_list"
                                    id="bank_code"
                                    v-model="inputs.bank_code"
                                    :page="page"
                                ></BaseSelect>
                            </div>

                            <div class="col-md-6">
                                <BaseSelect 
                                    label="Tipo de conta"
                                    :items="account_type"
                                    id="bank_type"
                                    v-model="inputs.bank_type"
                                    :page="page"
                                ></BaseSelect>
                            </div>

                            <div class="col-md-4">
                                <BaseInput
                                    label="Agência"
                                    type="tel"
                                    id="bank_agency"
                                    v-model="inputs.bank_agency"
                                    :page="page"
                                    mask="###############"
                                />
                            </div>

                            <div class="col-md-4">
                                <BaseInput
                                    label="Conta"
                                    type="tel"
                                    id="bank_number"
                                    v-model="inputs.bank_number"
                                    :page="page"
                                    mask="###############"
                                />
                            </div>

                            <div class="col-md-4">
                                <BaseInput
                                    label="Dígito da conta"
                                    type="tel"
                                    id="bank_number_digit"
                                    v-model="inputs.bank_number_digit"
                                    :page="page"
                                    mask="###"
                                />
                            </div>

                        </div>

                    </div>
                    <BaseCheckBox
                        label="Declaro que os dados preenchidos acima são válidos e que todas as informações para reembolso pertencem a mesma titularidade."
                        class="mb-0"
                        id="declaration"
                        v-model:checked="inputs.declaration"
                        :page="page"
                    ></BaseCheckBox>
                </div>

                <div class="row">
                    <div class="col-12">
                        <BaseButton 
                            class="btn-primary d-block w-100 mt-3 mb-2"
                            :loading="formLoading"
                            :disabled="formLoading"
                        >Solicitar</BaseButton>
                    </div>
                </div>

            </form>

        </div>
        <TheLoading v-else></TheLoading>
    </BaseDialog>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import HeaderOrder from "@/components/layout/order/HeaderOrder";
import TheLoading from '@/components/layout/includes/TheLoading';
import BaseSelect from "@/components/ui/BaseSelect";
import InfoOrder from '@/components/layout/order/InfoOrder';
import TableOrder from '@/components/layout/order/TableOrder';
import BaseCheckBox from '@/components/ui/BaseCheckBox';
import BaseFileUpload from '@/components/ui/BaseFileUpload';

const page = ref('refundRequest');

import { useRoute } from "vue-router";
const route = useRoute();
const order_key = computed(function () {
    return route.params.code;
});

import { useOrderStore } from "@/store/order";
const orders = useOrderStore();

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

const isLoading = ref(true);

const formLoading = ref(false);

onMounted(() => {
    delete global.errors[page.value];
    if (orders.currentOrder && orders.refund) {
        if(orders.currentOrder.order_key != order_key.value) {
            orders.tryOrder(order_key.value);
        }

        if(orders.refund.order_key != order_key.value) {
            orders.tryRefundCheck(order_key.value).then(() => {
                isLoading.value = false;
            });
        }else {
            isLoading.value = false;
        }
    } else {
        if(!orders.currentOrder) {
            orders.tryOrder(order_key.value);
        }

        if(!orders.refund) {
            orders.tryRefundCheck(order_key.value).then(() => {
                isLoading.value = false;
            });
        }else {
            isLoading.value = false;
        }
    }
});

const order = computed(function () {
    return orders.currentOrder;
});

const refund = computed(function () {
    return orders.refund;
});


let refund_methods = {
    'pix': 'PIX',
    'ted': 'Transferência bancária'
}

let account_type = {
    'C': 'Corrente',
    'P': 'Poupança'
}

const inputs = ref({
    refund_reason: '',
    refund_text: '',
    refund_images: null,
    name: '',
    cpf: '',
    refund_method: null,
    pix_type: null,
    pix_key: '',
    bank_code: '',
    bank_type: '',
    bank_agency: '',
    bank_number: '',
    bank_number_digit: '',
    declaration: false
});

const pix_key_type = ref('');
const pix_key_field = ref('text');

function changePixType() {
    inputs.value.pix_key = '';
    document.getElementById('pix_key').removeAttribute("data-mask")
    if(inputs.value.pix_type == 'CNPJ') {
        pix_key_type.value = '##.###.###/####-##';
        pix_key_field.value = 'tel';
    }else if(inputs.value.pix_type == 'CPF') {
        pix_key_type.value = '###.###.###-##';
        pix_key_field.value = 'tel';
    }else if(inputs.value.pix_type == 'TELEFONE') {
        pix_key_type.value = ['(##) ####-####', '(##) #####-####'];
        pix_key_field.value = 'tel';
    }else if(inputs.value.pix_type == 'EMAIL' || inputs.value.pix_type == 'CHAVE_ALEATORIA') {
        pix_key_type.value = '';
        pix_key_field.value = 'text';
    }
}

async function refundSumbit() {

    if(formLoading.value === true) {
        return;
    }

    let errors = false;

    if(!inputs.value.refund_reason) {
        global.tryLocalErrors('refund_reason', 'É necessário escolher um motivo', page.value);
        errors = true;
    }

    if (!inputs.value.refund_text || inputs.value.refund_text.length < 10) {
        global.tryLocalErrors('refund_text', 'Detalhes da solicitação necessários, mínimo 10 caracteres', page.value);
        errors = true;
    }

    if(inputs.value.refund_images && inputs.value.refund_images.length > 3) {
        global.tryLocalErrors('refund_images', 'Selecione até 3 imagens, '+ inputs.value.refund_images.length +' selecionadas', page.value);
        errors = true;
    }

    if(orders.currentOrder.payment_info.type != 'credit-card') {

        if(!inputs.value.name) {
            global.tryLocalErrors('name', 'Digite seu nome completo', page.value);
            errors = true;
        }

        if(!inputs.value.cpf || inputs.value.cpf.length < 14) {
            global.tryLocalErrors('cpf', 'CPF obrigatório', page.value);
            errors = true;
        }

        if(!inputs.value.refund_method) {
            global.tryLocalErrors('refund_method', 'Selecione como deseja receber o reembolso', page.value);
            errors = true;
        }else {

            if(inputs.value.refund_method == 'pix') {

                if(!inputs.value.pix_type) {
                    global.tryLocalErrors('pix_type', 'É necessário escolher um tipo de chave', page.value);
                    errors = true;
                }

                if(!inputs.value.pix_key) {
                    global.tryLocalErrors('pix_key', 'Campo obrigatório', page.value);
                    errors = true;
                }

            }else if(inputs.value.refund_method == 'ted') {
                
                if(!inputs.value.bank_code) {
                    global.tryLocalErrors('bank_code', 'Selecione um banco', page.value);
                    errors = true;
                }

                if(!inputs.value.bank_type) {
                    global.tryLocalErrors('bank_type', 'Selecione um tipo de conta', page.value);
                    errors = true;
                }

                if(!inputs.value.bank_agency) {
                    global.tryLocalErrors('bank_agency', 'Agência necessária', page.value);
                    errors = true;
                }

                if(!inputs.value.bank_number) {
                    global.tryLocalErrors('bank_number', 'Conta necessária', page.value);
                    errors = true;
                }

                if(!inputs.value.bank_number_digit) {
                    global.tryLocalErrors('bank_number_digit', 'Dígito da conta necessária', page.value);
                    errors = true;
                }
            }else {
                global.tryLocalErrors('refund_method', 'Opção inválida', page.value);
                errors = true;
            }
        }

        if(!inputs.value.declaration) {
            global.tryLocalErrors('declaration', 'O campo acima é obrigatório', page.value);
            errors = true;
        }

    }
    
    if(!errors) {
        formLoading.value = true;
        const params = inputs.value;
        orders.tryRefundRequest(page.value, order_key.value, params).then(() =>{
            formLoading.value = false;
        });
    }
}
</script>
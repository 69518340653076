<template>
    <BaseDialog>
        <div v-if="!isLoading && order.id">
            <HeaderOrder :order="order" backTo="/" />
            <div
                v-if="showRefundMessage"
                class="alert alert-success alert-dismissible fade show"
                role="alert"
            >
                <h2 class="alert-heading">
                    Cancelamento solicitado com sucesso!
                </h2>
                <button
                    @click="closeRefundMessage"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                ></button>
                <p>Sua solicitação será analizada em até 10 dias.</p>
                <hr />
                <p class="mb-0">
                    Para acompanhar basta acessar "solicitação de reembolso"
                    logo abaixo.
                </p>
            </div>

            <CustomFieldsOrder
                v-if="order.fields"
                :fields="order.fields"
                :page="page"
                :order_key="order.order_key"
            ></CustomFieldsOrder>

            <ContentOrder v-if="JSON.stringify(order.courses) !== '{}'" :courses="order.courses" :order_key="order_key"></ContentOrder>

            <FilesOrder
                v-if="order.files.length > 0"
                :files="order.files"
            ></FilesOrder>

            <InfoOrder :info="order.info" :seller="order.seller"></InfoOrder>

            <PaymentInfo
                :info="order.info"
                :shipping_address="order.shipping_address"
            ></PaymentInfo>

            <RecurrenceOrder
                v-if="order.recurrence"
                :recurrence="order.recurrence"
                @cancelRecurrenceRequest="cancelRecurrenceRequest"
            ></RecurrenceOrder>

            <div
                v-if="order.payment_info && order.payment_info.boleto"
                class="mb-3 text-center"
            >
                <BaseButton
                    class="btn-primary btn-lg"
                    link
                    :to="order.payment_info.boleto"
                    target="_blank"
                    >Acessar boleto</BaseButton
                >
            </div>

            <div
                v-if="order.payment_info && order.payment_info.pix"
                class="my-4 text-center"
            >
                <BaseButton @click="showQrCode" class="btn-primary btn-lg"
                    >Exibir Código pix</BaseButton
                >
                <div id="qrCodeDiv" class="mt-3 toggleShow">
                    <BaseQrCode :text="order.payment_info.pix"></BaseQrCode>
                    <div class="mt-4 position-relative">
                        <input
                            type="text"
                            :value="order.payment_info.pix"
                            id="pix"
                        />
                        <button @click="copyPix" class="copy_pix">
                            Copiar código
                        </button>
                    </div>
                </div>
            </div>
            <TrackingOrder :tracking="order.tracking"></TrackingOrder>

            <TableOrder
                v-if="order.products"
                :products="order.products"
                :amount="order.amount"
                :shipping="order.shipping"
                :discount="order.discount"
            ></TableOrder>

            <RefundOrder
                v-if="order.refund"
                :refund="order.refund"
            ></RefundOrder>

            <p class="text-center mb-0 fw-light need_help_text">
                <router-link :to="order.order_key + `/faq`"
                    >Precisa de ajuda?</router-link
                >
            </p>
        </div>
        <TheLoading v-else></TheLoading>
    </BaseDialog>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import HeaderOrder from "@/components/layout/order/HeaderOrder";
import TheLoading from "@/components/layout/includes/TheLoading";
import BaseQrCode from "@/components/ui/BaseQrCode";
import FilesOrder from "@/components/layout/order/FilesOrder";
import InfoOrder from "@/components/layout/order/InfoOrder";
import TableOrder from "@/components/layout/order/TableOrder";
import TrackingOrder from "@/components/layout/order/TrackingOrder";
import PaymentInfo from "@/components/layout/order/PaymentInfoOrder";
import RecurrenceOrder from "@/components/layout/order/RecurrenceOrder";
import RefundOrder from "@/components/layout/order/RefundOrder";
import CustomFieldsOrder from "@/components/layout/order/CustomFieldsOrder";
import ContentOrder from "@/components/layout/order/ContentOrder";


import { useRoute } from "vue-router";

const route = useRoute();
const order_key = computed(function () {
    return route.params.code;
});

import { useOrderStore } from "@/store/order";
const orders = useOrderStore();

const isLoading = ref(true);

onMounted(() => {
    if (orders.currentOrder &&  orders.currentOrder.order_key == order_key.value) {
        isLoading.value = false;
    } else {
        orders.tryOrder(order_key.value).then(() => {
            isLoading.value = false;
        });
    }
});

const order = computed(function () {
    return orders.currentOrder;
});

const showRefundMessage = computed(function () {
    let session_order_key = sessionStorage.getItem("refundCreatedFor");
    return order_key.value == session_order_key ? true : false;
});

function closeRefundMessage() {
    sessionStorage.removeItem("refundCreatedFor");
}

function copyPix() {
    var copyText = document.getElementById("pix");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
}

const page = ref("order");


function cancelRecurrenceRequest() {

    orders.tryCancelRecurrence(page.value, orders.currentOrder.order_key).then(() => {
        isLoading.value = true;
        orders.tryOrder(order_key.value).then(() => {
            isLoading.value = false;
        });
    });
}

function showQrCode() {
    var qrCodeDiv = document.querySelector("#qrCodeDiv");
    qrCodeDiv.classList.toggle("toggleShow");
}

</script>

<style scoped>
.btn-primary {
    padding: 8px 70px 9px 70px;
    width: 100%;
}

#pix {
    width: 100%;
    display: block;
    padding: 8px 10px;
    border: 1px solid #d7d7d7;
    padding-right: 140px;
    border-radius: 40px;
    outline: none;
}

.copy_pix {
    position: absolute;
    background: #000;
    color: #fff;
    top: 3px;
    right: 3px;
    padding: 5px 15px 6px 14px;
    border-radius: 25px;
    font-size: 14px;
    border: 0;
}

.copy_pix:hover {
    background: #3a3a3a;
}

.toggleShow {
    display: none;
}

.need_help_text {
    font-weight: 500 !important;
    font-size: 14px !important;
}

.alert-heading {
    font-size: 18px;
}
</style>
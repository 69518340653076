<template>
    <TheHeader />
    <div class="w-100">
        <div class="container-fluid">
            <div class="container">
                <div class="box p-4">
                    <div class="mb-4">
                        <h2>
                            <svg class="domain_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"/></svg>
                            <span> Meus Dados</span>
                        </h2>
                    </div>

                    <form @submit.prevent="accountSubmit">
                        <div class="row align-items-start">
                            <div class="col-12">
                                <BaseInput
                                    label="Nome *"
                                    type="text"
                                    id="name"
                                    v-model="inputs.name"
                                    :page="page"
                                />
                            </div>

                            <div class="col-md">
                                <BaseInput
                                    label="E-mail *"
                                    type="text"
                                    id="email"
                                    v-model="inputs.email"
                                    :page="page"
                                />
                            </div>

                            <div class="col-md">
                                <BaseInput
                                    label="Senha"
                                    type="password"
                                    id="password"
                                    v-model="inputs.password"
                                    :page="page"
                                />
                            </div>

                            <div class="col-md">
                                <BaseButton 
                                    class="btn-primary d-block d-blox w-100"
                                    style="margin-top:30px"
                                    :disabled="isLoading"
                                >Salvar</BaseButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="loader_body" v-show="isLoading">
        <div></div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import TheHeader from "@/components/layout/includes/TheHeader";

import { useUserStore } from "@/store/user";
const user = useUserStore();

const isLoading = ref(false);

import { useAuthStore } from "@/store/auth";
const loggedUser = useAuthStore();

const page = ref('accountEdit');
const inputs = ref({
    name: loggedUser.name,
    email: loggedUser.email,
    password: ''
});

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

onMounted(() => {
    delete global.errors[page.value];
});

let toastIs = true;

function accountSubmit() {
    let errors = false;
    const emailPattern = /^([\w.*-]+@([\w-]+\.)+[\w-]{2,4})?$/;

    if(!inputs.value.name) {
        global.tryLocalErrors('name', 'Digite seu nome', page.value);
        errors = true;
    }

    if (inputs.value.email === "" || !inputs.value.email.match(emailPattern) ) {
        global.tryLocalErrors('email', 'Digite um e-mail válido', page.value);
        errors = true;
    }

    if(!errors && toastIs == true) {
        isLoading.value = true;
        toastIs = false;
        const params = inputs.value;
        inputs.value.password = '';
        user.tryEditUser(page.value, params).then((response) =>{
            isLoading.value = false;
            setTimeout(function() {
                toastIs = true;
            }, 2000);
            if(response) {
                global.trySuccessMessage('Informações alteradas com sucesso');
            }
        });
    }
}
</script>

<style scoped>
.box {
    background: #fff;
    border: 1px #d7d7d7 solid;
    border-radius: 8px;
}

h2 {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

.domain_svg {
    margin-right: 10px;
    width: 18px;
}
</style>